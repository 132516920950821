import { InputNumber, Select } from "antd"
import { observer } from "mobx-react-lite"

import styles from "./discount.module.scss"

const { Option } = Select
export const Discount = observer(
	({
		valueInputInterest,
		onChangeInputInterest,
		valueInputPrice,
		onChangeInputPrice,
		valueSelect,
		onChangeSelect,
	}) => {
		const onValidationPrice = (value) => {
			return value > 999 ? new Intl.NumberFormat("ru-RU").format(value).replace(/^\d+$/, ",") : value
		}

		const onValidationInterest = (value) => {
			return value.replace(/[^0-9]/g, "")
		}

		return (
			<div className={styles.form}>
				{valueSelect === 0 ? (
					<InputNumber
						className={styles.formInput}
						size="large"
						controls={false}
						min={1}
						value={valueInputPrice}
						formatter={onValidationPrice}
						onChange={onChangeInputPrice}
					/>
				) : (
					<InputNumber
						className={styles.formInput}
						size="large"
						controls={false}
						min={1}
						max={100}
						formatter={onValidationInterest}
						value={valueInputInterest}
						onChange={onChangeInputInterest}
					/>
				)}

				<Select
					className={styles.formSelect}
					size="large"
					value={valueSelect}
					onChange={onChangeSelect}
					defaultValue={0}
				>
					<Option value={0}>₽</Option>
					<Option value={1}>%</Option>
				</Select>
			</div>
		)
	},
)
