import SEO from "@americanexpress/react-seo"
import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Divider, Input, Row, Select, Space, Switch, Typography, Upload, message } from "antd"
import locale from "antd/es/date-picker/locale/ru_RU"
import { observer } from "mobx-react-lite"
import moment from "moment"
import "moment/locale/ru"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import HeaderComponent from "components/Header/Header"

import { coursesStore } from "store/Courses/CoursesStore"
import { usersStore } from "store/Users/UsersStore"
import { webinarsStore } from "store/WebinarsStore/WebinarsStore"

import dateFormat from "hooks/dateFormat"

import "./CreateWebinarsPage.scss"

const { Title } = Typography
const { Option } = Select
const CreateWebinarsPage = () => {
	const params = useParams()
	const { created_by, updated_by, date_create, date_update } = webinarsStore.webinar

	const uploadProps = {
		fileList: webinarsStore.webinar.photo,
		accept: "image/jpeg, image/png, image/gif",
		maxCount: 1,
		listType: "picture-card",
		showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
		onRemove: (file) => {
			webinarsStore.webinar.photo = []
		},
		beforeUpload: (file) => {
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif"

			if (!isJpgOrPng) {
				message.error("Можно загружать только JPG/PNG/GIF файл!")
			}
			webinarsStore.webinar.photo = [file]
			return false
		},
	}
	useEffect(() => {
		coursesStore.getAllCourses(0, 0, 0)
		if (params.id > 0) {
			webinarsStore.getWebinar(Number(params.id))
		} else {
			webinarsStore.setWebinar()
		}

		if (created_by) {
			usersStore.getUserEditor(created_by, "create")
		}
		if (updated_by) {
			usersStore.getUserEditor(updated_by, "edit")
		}
	}, [params.id, created_by, updated_by])

	return (
		<>
			<SEO title={params.id > 0 ? "Редактирование вебинара" : "Новый вебинар"} />
			<HeaderComponent />
			<div className="container">
				<form
					onSubmit={(e) => {
						webinarsStore.editWebinar(e)
					}}
					className="CreateWebinars"
				>
					<Row className="CreateUsers__row" gutter={[15, 24]}>
						<Col xs={24} lg={18} className="CreateWebinars__title">
							<Row gutter={[15, 24]} justify="space-between">
								<Col xs={24} md={18} lg={16}>
									<Title>{params.id > 0 ? "Редактирование вебинара" : "Новый вебинар"}</Title>
								</Col>

								<Col xs={24} md={6} lg={5} className="CreateWebinars__title-block">
									<Space>
										{params.id > 0 && (
											<Button
												size="large"
												href={"https://student.modnayakontora.ru/webinar/" + params.id}
												target="_blank"
											>
												Просмотр
											</Button>
										)}
										<Button
											size="large"
											type="primary"
											htmlType="submit"
											className="CreateWebinars__title-button"
										>
											{params.id > 0 ? "Сохранить" : "Создать"}
										</Button>
									</Space>
								</Col>
							</Row>
						</Col>

						<Col xs={24} lg={18} className="CreateWebinars__content">
							<Row gutter={[15, 24]}>
								<Col xs={24} lg={12}>
									<Space direction="vertical">
										<Title level={4}>Название</Title>
										<Input
											size="large"
											placeholder="Введите название"
											value={webinarsStore.webinar.name}
											onChange={(e) => webinarsStore.onChange("name", e.target.value)}
										/>
									</Space>
								</Col>

								<Col xs={24} lg={12}>
									<Space direction="vertical">
										<Title level={4}>Связанный курс</Title>
										<Select
											size="large"
											allowClear
											placeholder="Выберите связанный курс"
											defaultValue={webinarsStore.webinar.course}
											value={webinarsStore.webinar.course}
											onChange={(e) => webinarsStore.onChange("course", e)}
										>
											{coursesStore.listCourses?.map((course) => (
												<Option key={course.id} value={course.id}>
													(ID: {course.id}) {course.title}{" "}
													{course?.date_start &&
														`(старт: ${course?.date_start?.split(" ")[0]})`}
												</Option>
											))}
										</Select>
									</Space>
								</Col>

								<Col xs={24} lg={12}>
									<Title level={4}>Обложка</Title>
									<Upload {...uploadProps}>
										<Button icon={<UploadOutlined />} />
									</Upload>
								</Col>

								<Col xs={12} lg={6}>
									<Space direction="vertical">
										<Title level={4}>Дата и время старта</Title>

										<DatePicker
											size="large"
											locale={locale}
											showTime={{
												defaultValue: moment("16:00", "HH:mm"),
											}}
											value={
												webinarsStore.webinar.date_start
													? webinarsStore.setDate(webinarsStore.webinar.date_start)
													: ""
											}
											format="DD.MM.YYYY HH:mm"
											onChange={(e) => webinarsStore.onChangeDate("date_start", e)}
										/>
									</Space>
								</Col>

								<Col xs={12} lg={6}>
									<Space direction="vertical">
										<Title level={4}>Время старта (текст)</Title>
										<Input
											size="large"
											placeholder="Введите время"
											value={webinarsStore.webinar.time_start}
											onChange={(e) => webinarsStore.onChange("time_start", e.target.value)}
										/>
									</Space>
								</Col>

								<Col xs={24} lg={6}>
									<Row gutter={[15, 24]}>
										<Space direction="vertical">
											<Title level={4}>Сервис</Title>
											<Select
												size="large"
												placeholder="Выберите сервис"
												allowClear
												defaultValue={webinarsStore.webinar.course}
												value={webinarsStore.webinar.video_type}
												onChange={(e) => webinarsStore.onChange("video_type", e)}
											>
												<Option value="youtube">YouTube</Option>
												<Option value="vk">VK</Option>
												<Option value="vimeo_video">Vimeo видео</Option>
												<Option value="vimeo_event">Vimeo трансляция</Option>
											</Select>
										</Space>
										{webinarsStore.webinar.video_type === "vk" && (
											<Space direction="vertical">
												<Title level={4}>Секретный ключ (Hash)</Title>
												<Input
													size="large"
													placeholder="Введите секретный ключ"
													value={webinarsStore.webinar.video_hash}
													onChange={(e) =>
														webinarsStore.onChange("video_hash", e.target.value)
													}
												/>
											</Space>
										)}
									</Row>
								</Col>
								<Col xs={24} lg={6}>
									<Space direction="vertical">
										<Title level={4}>ID трансляции</Title>
										<Input
											size="large"
											placeholder="Введите ID трансляции"
											value={webinarsStore.webinar.webinar_stream}
											onChange={(e) => webinarsStore.onChange("webinar_stream", e.target.value)}
										/>
									</Space>
								</Col>

								<Col xs={24} md={6} lg={6} className="CreateWebinars__switch">
									<Space direction="vertical">
										<Title level={4}>Статус вебинара</Title>
										<Switch
											className="CreateWebinars__switch-switch"
											checked={Boolean(Number(webinarsStore.webinar.status))}
											checkedChildren="Активен"
											unCheckedChildren="Не активен"
											onChange={(e) => {
												if (e === true) {
													webinarsStore.onChange("status", 1)
												} else {
													webinarsStore.onChange("status", 0)
												}
											}}
										/>
									</Space>
								</Col>

								<Col xs={24} md={6} lg={6} className="CreateWebinars__switch">
									<Space direction="vertical">
										<Title level={4}>Показывать цены</Title>
										<Switch
											className="CreateWebinars__switch-switch"
											checked={Boolean(Number(webinarsStore.webinar.show_prices))}
											checkedChildren="Да"
											unCheckedChildren="Нет"
											onChange={(e) => {
												if (e === true) {
													webinarsStore.onChange("show_prices", 1)
												} else {
													webinarsStore.onChange("show_prices", 0)
												}
											}}
										/>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
				</form>
				<div style={{ paddingBottom: "30px" }}>
					<Divider />
					<Row gutter={[24, 24]} justify="center">
						{usersStore.createUserStatus === true && (
							<Col xs={24} sm={12} md={8}>
								<div>
									<strong>Дата создания:</strong> {dateFormat(date_create)}
								</div>
								<div>
									<strong>Кем было создано:</strong> {usersStore.createItemUser.name}
								</div>
							</Col>
						)}

						{usersStore.editUserStatus === true && (
							<Col xs={24} sm={12} md={8}>
								<div>
									<strong>Дата изменния:</strong> {dateFormat(date_update)}
								</div>
								<div>
									<strong>Кем было изменено:</strong> {usersStore.editItemUser.name}
								</div>
							</Col>
						)}
					</Row>
				</div>
			</div>
		</>
	)
}

export default observer(CreateWebinarsPage)
